<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        <span>Learning Materials</span>
                        <v-spacer></v-spacer>
                        <add-button permission="learning-materials-create" :disabled="!subjectId"
                                    @action="form.syllabus_id=syllabusId,form.dialog = true,form.grade_id=gradeId,form.subject_id=subjectId">
                            Add New
                            Material
                        </add-button>
                    </v-card-title>


                    <v-card-title>
                        <v-flex xs3>
                            <v-grade-field v-model="gradeId"></v-grade-field>
                        </v-flex>

                        <v-flex xs3>
                            <v-subject-field v-model="subjectId" :grade-id="gradeId"></v-subject-field>
                        </v-flex>

                        <v-flex xs4>
                            <treeselect placeholder="Select Syllabus"
                                        style="font-size: 14px;font-weight: normal;color: #777;height: 41px;"
                                        v-model="syllabusId"
                                        :options="syllabus"/>
                        </v-flex>
                        <search-button :disabled="!subjectId" style="" permission="receipt-read"
                                       @action="get()">
                            Search
                        </search-button>
                    </v-card-title>

                    <v-data-table
                            :expanded.sync="expanded"
                            :single-expand="singleExpand"
                            :headers="headers"
                            hide-default-footer
                            :loading="form.loading"
                            :items="form.items.data"
                            :search="search"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total">
                        <template v-slot:item="{index, item, expand, isExpanded}" v-slot:top>
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.title }}</td>
                                <td class="text-xs-left">{{ item.subject?item.subject.name:'-' }}</td>
                                <td>
                                    <template v-for="(document,key) in item.documents">
                                        <v-icon :key="key" class="mr-2" style="cursor: pointer;"
                                                @click="viewDocument(document)">{{getIcons(document.original_name)}}
                                        </v-icon>
                                    </template>
                                    <template v-if="item.video_key">
                                        <v-icon class="mr-2" style="cursor: pointer;"
                                                @click="viewVideo(item.video_key)">airplay
                                        </v-icon>
                                    </template>
                                </td>
                                <td class="text-right">
                                    <v-btn outlined x-small :color="'primary'"
                                           @click="form.dialog=true,form.parent_id=item.id,form.subject_id=item.subject_id,form.grade_id=item.grade_id">
                                        <v-icon left small>{{ 'add_circle' }}</v-icon>
                                        Add Child
                                    </v-btn>&nbsp;

                                    <view-button v-if="item.children.length>0" permission="learning-materials-read"
                                                 @action="expand(!isExpanded)"/>
                                    <edit-button permission="learning-materials-update" @agree="form.edit(item)"/>
                                    <delete-button permission="learning-materials-delete"
                                                   @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-simple-table>
                                    <template :headers="headers"
                                              :items="item.child"
                                              v-slot:default>
                                        <tbody>
                                        <tr class="table_child" v-for="(child,key) in item.children" :key="key">
                                            <td width="6%"></td>
                                            <!--<td width="157" class="text-left">&nbsp;</td>-->
                                            <td width="425" class="text-left">{{child.title}}</td>
                                            <td width="100" class="text-left">
                                                <template v-for="(document,key) in child.documents">
                                                    <v-icon :key="key" class="mr-2" style="cursor: pointer;"
                                                            @click="viewDocument(document)">
                                                        {{getIcons(document.original_name)}}
                                                    </v-icon>
                                                </template>
                                                <template v-if="child.video_key">
                                                    <v-icon class="mr-2" style="cursor: pointer;"
                                                            @click="viewVideo(child.video_key)">airplay
                                                    </v-icon>
                                                </template>
                                            </td>
                                            <td class="text-right">
                                                <edit-button permission="learning-materials-update"
                                                             @agree="form.edit(child)"/>
                                                <delete-button permission="learning-materials-delete"
                                                               @agree="form.delete(child.id)"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>

                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer/>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>

            <v-dialog v-model="contentDialog" fullscreen hide-overlay>
                <v-card outlined class="primary--border">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="contentDialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-spacer></v-spacer>
                        <v-toolbar-items @click="downloadDocument()" v-if="!documentViewer.video">
                            <v-btn dark text>Download</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text class="ma-0 pa-0">
                        <template v-if="documentViewer.video">
                            <iframe style="width: 100%; height: calc(100vh - 80px); margin-top: 5px;"
                                    :src="documentViewer.url" frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                        </template>
                        <template v-else-if="documentViewer.iframe">
                            <iframe :src="documentViewer.url" style="width: 100%; height: calc(100vh - 40px);"
                                    no-border></iframe>
                        </template>
                        <img v-else :src="documentViewer.url" style="width: 500px; margin: auto; display: block;"/>

                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="form.dialog" scrollable persistent max-width="500px">
                <v-card outlined class="primary--border">
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                lazy-validation>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12 class="mt-2">
                                        <v-select v-if="gradeMaterials.length>0" :items="gradeMaterials"
                                                  v-model="form.parent_id"
                                                  :error-messages="form.errors.get('parent_id')"
                                                  outlined dense
                                                  class="pa-0" :label="'Select parent'"/>
                                    </v-flex>
                                    <!--<v-flex xs1 class="mt-2">-->
                                    <!--<v-btn @click="form.parent_id=''">Clear</v-btn>-->
                                    <!--</v-flex>-->
                                    <v-flex xs12 class="mt-2">
                                        <v-text-field
                                                label="Title*" autocomplete="off" required class="pa-0"
                                                v-model="form.title" name="name" outlined dense
                                                :error-messages="form.errors.get('name')"/>
                                    </v-flex>

                                    <v-flex xs12>
                                        <treeselect style="margin-top: -10px;margin-bottom: 15px;"
                                                    v-model="form.syllabus_id" :options="syllabus"/>
                                    </v-flex>
                                    <v-flex xs12 class="mt-2">
                                        <v-text-field
                                                label="Youtube Video (Paste Video Link)" autocomplete="off" required
                                                class="pa-0"
                                                v-model="form.video_key" name="name" outlined dense
                                                :error-messages="form.errors.get('name')"/>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-grade-field label="Select Grade*" v-model="form.grade_id"></v-grade-field>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-subject-field v-model="form.subject_id" label="Select Subject*"
                                                         :grade-id="form.grade_id"></v-subject-field>
                                    </v-flex>



                                    <v-flex>
                                        <v-file-input ref="multipleFiles" outlined dense :clearable="false"
                                                      v-on:change="handleFilesUpload"
                                                      multiple label="Add Files">
                                            <template v-slot:selection="{ text}">
                                                <v-chip
                                                        small
                                                        label
                                                        color="primary">
                                                    {{ text }}
                                                </v-chip>
                                            </template>

                                        </v-file-input>
                                    </v-flex>
                                    <v-uploaded-showcase :documents="documents"></v-uploaded-showcase>
                                    <v-flex xs12>
                                        <br>
                                        <v-textarea outlined dense
                                                    v-model="form.description"
                                                    name="description"
                                                    label="Description*"/>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text :loading="form.loading" @click="store">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'
    import {mapState, mapActions} from 'vuex';
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Mixins from '@/library/Mixins'

    export default {
        mixins: [Mixins],
        props: {
            schoolId: {
                required: false,
            }
        },
        components: {Treeselect},

        data: () => ({

            expanded: [],
            singleExpand: true,

            contentDialog: false,
            documentViewer: {
                iframe: false,
                url: null
            },
            menu2: '',
            menu1: '',
            gradeId: '',
            subjectId: '',
            syllabusId: null,//15
            syllabus: [],
            form: new Form({
                parent_id: '',
                syllabus_id: '',
                dialog: false,
                subject_id: '',
                video_key: '',
                grade_id: '',
                title: '',
                description: '',
            }, '/api/learning-materials'),
            documents: [],
            search: null,
            pagination: {
                itemsPerPage: 20
            },
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 80},
                {text: 'Title', align: 'left', value: 'name', sortable: false, width: 300},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false},
                {text: 'Documents', align: 'left', value: 'document', sortable: false, width: 250},
                {text: 'Action', align: 'right', sortable: false, width: 400},

            ],
            grades: [],
            sections: [],
            subjects: [],
            statuses: [{
                text: 'Live',
                value: 'live'
            }],
            fetch: true
        }),

        watch: {
            'pagination': function () {
                this.get();
            },
            'gradeId': function () {
                this.subjectId = '';
                this.get();
            },
            'subjectId': function (value) {
                this.fetchSyllabus(value);


            },
            'syllabusId': function (value) {
                // if (this.fetch) {
                // this.get();
                // }
            },
            'form.subject_id': function (value) {
                this.fetchSyllabus(value);
            },
            contentDialog: function (value) {
                if (value === false) {
                    this.documentViewer = {
                        iframe: false,
                        url: null
                    }
                }
            }
        },

        mounted() {
            this.getGrades();
            if (this.$route.params.subjectId && this.$route.params.gradeId && this.$route.params.syllabusId) {
                this.gradeId = parseInt(this.$route.params.gradeId);
                this.subjectId = parseInt(this.$route.params.subjectId);
                this.syllabusId = parseInt(this.$route.params.syllabusId);
                this.get();
            }
        },

        computed: {
            ...mapState(['grade', 'subject']),
            gradeMaterials() {
                return this.form.items.data.map((data) => {
                    return {value: data.id, text: data.title};
                });
            }
        },

        methods: {
            ...mapActions(['getGrades']),


            get(params) {
                if (this.gradeId && this.subjectId) {
                    // let query = [null, undefined].includes(params) ? this.queryString() : params;
                    let extraParams = 'gradeId=' + this.gradeId + '&subjectId=' + this.subjectId;
                    this.form.get(null, this.queryString(extraParams)).then(({data}) => {
                        // this.pagination.totalItems = data.meta.total;
                    })
                }
            },

            fetchSyllabus(sub) {
                if (sub)
                    this.$rest.get('/api/syllabus?subjectId=' + sub).then(({data}) => {

                        let syllabusColl = [];
                        data.data.map(function (item, i) {
                            if (!item.chapters.length) {
                                syllabusColl.push({id: item.id, label: item.chapter});
                            } else {
                                syllabusColl.push({id: item.id, label: item.chapter});
                                syllabusColl[i].children = [];

                                item.chapters.map((child) => {
                                    syllabusColl[i].children.push({id: child.id, label: child.chapter})
                                });
                            }
                        });
                        this.syllabus = syllabusColl;

                    });
            },

            store() {
                const formData = new FormData();
                const data = this.form.data();


                Object.keys(this.form.data()).map((keys) => {
                    formData.append(keys, data[keys]);
                });

                Array.from(this.documents).forEach((file) => {
                    formData.append("documents[]", file);
                });
                let endpoint = '/api/learning-materials';
                if (this.form.id) {
                    endpoint = endpoint + '/' + this.form.id;
                    formData.append('_method', 'PUT');
                }


                this.$rest.post(endpoint, formData).then((res) => {
                    this.$events.fire("notification", {
                        message: res.data.message,
                        status: "success",
                    });
                    this.get();
                    this.form.dialog = false;
                });

            },

            handleFilesUpload(file) {
                this.documents = file;
            },

            removeImage(index) {
            },

            typeFormat(fileName) {
                if (!fileName) return;

                const fileParts = fileName.split('.');
                const extension = fileParts[fileParts.length - 1];

                //https://www.computerhope.com/issues/ch001789.htm
                const extensionList = {
                    pdf: 'PDF',
                    doc: 'WORD',
                    docx: 'WORD',
                    xls: 'EXCEL',
                    xlsx: 'EXCEL',
                    ppt: 'POWERPOINT',
                    pptx: 'POWERPOINT',
                    txt: 'TEXT',
                    png: 'IMAGE',
                    jpg: 'IMAGE',
                    jpeg: 'IMAGE',
                    gif: 'IMAGE',
                };
                if (extensionList[extension.toLowerCase()]) {
                    return extensionList[extension.toLowerCase()];
                }
                return 'UNKNOWN FILE FORMAT';
            },

            deleteImage(index) {
                this.documents.splice(index, 1);
            },

            getIcons(name) {
                if (!name) return ' error';

                const extensionList = {
                    pdf: 'picture_as_pdf',
                    doc: 'article',
                    docx: 'article',
                    xls: 'border_all',
                    xlsx: 'border_all',
                    ppt: 'slideshow',
                    pptx: 'slideshow',
                    txt: 'subject',
                    png: 'perm_media',
                    jpg: 'perm_media',
                    jpeg: 'perm_media',
                    gif: 'perm_media',
                };
                const fileParts = name.split('.');
                const extension = fileParts[fileParts.length - 1];


                if (extensionList[extension.toLowerCase()]) {
                    return extensionList[extension.toLowerCase()];
                }
                return 'error';
            },

            viewVideo(videoUrl) {
                try {
                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                    var match = videoUrl.match(regExp);
                    var id = (match && match[7].length === 11) ? match[7] : false;
                    if (id) {
                        this.documentViewer = {
                            video: true,
                            iframe: false,
                            url: `https://www.youtube.com/embed/${id}?controls=0`
                        };
                        this.contentDialog = true;
                    }
                } catch {
                    //TODO: handle catch...
                }
            },

            viewDocument(document) {
                if (!document || !document.original_name) return;

                // https://view.officeapps.live.com/op/embed.aspx?src=
                let imageType = ["jpg", "jpeg", "bmp", "gif", "png"];
                let docType = ['doc', 'docx', 'xlsx', 'txt', 'pptx', 'ppt', 'xls'];
                let extension = document.original_name.split('.').pop();
                if (extension = 'pdf') {
                    this.documentViewer = {
                        iframe: true,
                        url: document.docs
                    };
                    this.contentDialog = true;
                } else if (imageType.includes(extension)) {
                    this.documentViewer = {
                        iframe: false,
                        url: document.docs
                    };
                    this.contentDialog = true;
                } else if (docType.includes(extension)) {
                    this.documentViewer = {
                        iframe: true,
                        url: `https://view.officeapps.live.com/op/embed.aspx?src=${document.docs}`
                    };
                    this.contentDialog = true;
                }
            },
            downloadDocument() {
                window.open(this.documentViewer.url, '_blank');
            }
        }
    }
</script>

<style lang="scss">
    .vue-treeselect--single {
        margin-top: -28px;
    }

    .vue-treeselect__control {
        height: 41px !important;
    }

    .uploaded-file-preview {
        /*display: flex;*/
        /*min-height: 40px;*/
        width: 100%;
        border-radius: 15px;
        /*justify-content: center;*/
        align-items: center;
        border: 1px solid #ccc;

        overflow: hidden;
        margin-bottom: 15px;

        .icon-holder {
            width: 20%;
            padding: 5px 0;
            float: left;
            flex: 1;
            /*height: 40px;*/
            background-color: #1976d2 !important;
            /*border: 1px solid #1976d2;*/

            text-align: center;
        }

        .file-info-holder {
            border-left: none;
            padding: 5px 15px;
            color: #777;
            width: 80%;
            float: right;
            position: relative;

            p {
                margin-bottom: 0;
                line-height: 1;
            }

            i, button {
                position: absolute;
                right: 5px;
                font-size: 18px;
                color: #666;
                top: 5px;
                cursor: pointer;
            }
        }

    }

    .table_child {
        background-color: #eee;
    }

</style>